<template>
	<v-app>
		<v-main>
			<router-view class="tw-min-h-screen" />
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: 'EmptyLayout',
}
</script>
